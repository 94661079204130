.mainColor{
    background-image: url("../../../Images/bg/wallpaper1.jpg");
    background-repeat: no-repeat, repeat;
    height: auto;
    background-position: center;
    background-size: cover;
    margin-top: 450px;
    visibility: initial;
    transform: translateY(24px) translateZ(0px);
    padding: 100px 0px;
    background-attachment: fixed;
}
.firstBox{
    transition-duration: 0.5s;
    cursor: pointer;
    background-image: url("../../../Images/about3.jpg");
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    will-change: transform;
    width: 100%;
    height: 300px;
    flex: 0 0 auto;
    border-radius: 0px 40px 40px;
    box-shadow: rgba(0, 0, 0, 0) 0px 1.67332px 1.67332px 0px, rgba(0, 0, 0, 0.01) 0px 3.98421px 3.98421px 0px, rgba(0, 0, 0, 0.02) 0px 7.25932px 7.25932px 0px, rgba(0, 0, 0, 0.03) 0px 12.0684px 12.0684px 0px, rgba(0, 0, 0, 0.047) 0px 19.5005px 19.5005px 0px, rgba(0, 0, 0, 0.08) 0px 31.9014px 31.9014px 0px, rgba(0, 0, 0, 0.137) 0px 54.9604px 54.9604px 0px, rgba(0, 0, 0, 0.25) 0px 100px 100px 0px;
}
.cardBottom{
    position: absolute;
    padding: 20px;
    overflow: visible;
    /*height: 120px;*/
    right: 0;
    bottom: 0;
    left: 0px;
    flex: 0 0 auto;
    border-radius: 40px;
    backdrop-filter: blur(40px);
    background-color: rgba(0, 0, 0, 0.2);
    --border-bottom-width: 1px;
    --border-top-width: 1px;
    --border-right-width: 1px;
    --border-left-width: 1px;
    --border-style: solid;
    --border-color: rgba(255, 255, 255, 0.1);
}
.cardHeading{

}
.cardFlex{
    display: flex;
    justify-content: space-between;
}
.leftFlex{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
}
.secoundPosi{
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    will-change: transform;
    width: 100%;
    height: 250px;
    flex: 0 0 auto;
    border-top-left-radius: 0;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    box-shadow: 0 40px 80px 0 rgba(0,0,0,.5);
    backdrop-filter: blur(30px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    /*opacity: 1;*/
    transform: perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0px);
}
.secounRoundBo{
    border: 1px solid white;
    padding: 10px;
    border-radius: 50%;
    position: absolute;
    width: 290px;
    overflow: visible;
    top: 0;
    bottom: 0;
}
.thirdBox{
    background-image: url("../../../Images/about2.jpg") !important;
    width: 100%;
    height: 400px !important;
}
.fourBox{
    height: 150px !important;
    background-image: none !important;
}
.fifthBox{
    background-image: url("../../../Images/about1.jpg") !important;
}

.firstBox:hover{
    box-shadow: none;
}
.mainTitle{
    font-size: 45px;
    line-height: 120%;
    text-align: center;
    font-weight: 600;
}
.svg{
    align-items: center;
}
.rightContent{
    text-align: center;
}
.rightContent .subtext{
    color: #d6cbff;
    font-size: 25px;
    margin: 0;
    line-height: 122%;
    padding: 0;
}
.learnBtn{
    border-radius: 24px 24px 0px !important;
    background: black !important;
    color: white !important;
    padding: 13px 30px !important;
    font-size: 17px !important;
    margin-top: 20px !important;
    transition-duration: 0.5s !important;
    margin-bottom: 20px !important;
}