
.timelineBox{
    margin-top: 30px;
}
.headersection{
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
}
.mainTitle{
    font-size: 45px;
    line-height: 120%;
    text-align: center;
    font-weight: 600;
    margin: 0 !important;
}