body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  box-sizing: border-box;
  padding: 0;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  margin: 0;
  padding: 0;
}
html,
body,
#root{
  height: 100%;
  background: #0d112b;
}


button {
  background-color: rgba(0, 0, 0, 0.2);
  border: 0px;
  font-size: 16px;
  padding: 12px 30px;
  border-radius: 14px;
  border: 1px solid rgba(225, 225, 225, 0.1);
  color: white;
  max-width: 280px;
  backdrop-filter: blur(20px);
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  transition: 1s;
  cursor: pointer;
}

button:hover {
  border: 1px solid white;
  transform: translateY(-3px);
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


p {
  font-weight: normal;
  line-height: 150%;
  max-width: 500px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}